define("discourse/plugins/discourse-reactions/discourse/components/discourse-reactions-actions-summary", ["exports", "@glimmer/component", "@ember/helper", "discourse/components/mount-widget", "@ember/component", "@ember/template-factory"], function (_exports, _component, _helper, _mountWidget, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ReactionsActionSummary extends _component.default {
    static extraControls = true;
    static shouldRender(args, context, owner) {
      const site = owner.lookup("service:site");
      if (site.mobileView || args.post.deleted) {
        return false;
      }
      const siteSettings = owner.lookup("service:site-settings");
      const mainReaction = siteSettings.discourse_reactions_reaction_for_like;
      return !(args.post.reactions && args.post.reactions.length === 1 && args.post.reactions[0].id === mainReaction);
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if @shouldRender}}
          {{! template-lint-disable no-capital-arguments }}
          <MountWidget
            @widget="discourse-reactions-actions"
            @args={{hash post=@post position="left"}}
          />
        {{/if}}
      
    */
    {
      "id": "twcpD1kO",
      "block": "[[[1,\"\\n\"],[41,[30,1],[[[1,\"      \"],[8,[32,0],null,[[\"@widget\",\"@args\"],[\"discourse-reactions-actions\",[28,[32,1],null,[[\"post\",\"position\"],[[30,2],\"left\"]]]]],null],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"@shouldRender\",\"@post\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-reactions/discourse/components/discourse-reactions-actions-summary.js",
      "scope": () => [_mountWidget.default, _helper.hash],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ReactionsActionSummary;
});