define("discourse/plugins/discourse-reactions/discourse/components/discourse-reactions-actions-button", ["exports", "@ember/helper", "discourse/components/mount-widget", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _helper, _mountWidget, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ReactionsActionButton = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    {{! template-lint-disable no-capital-arguments }}
    <MountWidget
      class="discourse-reactions-actions-button-shim"
      @widget="discourse-reactions-actions"
      @args={{hash post=@post showLogin=@buttonActions.showLogin}}
    />
  
  */
  {
    "id": "L0ZVm9QZ",
    "block": "[[[1,\"\\n\"],[1,\"  \"],[8,[32,0],[[24,0,\"discourse-reactions-actions-button-shim\"]],[[\"@widget\",\"@args\"],[\"discourse-reactions-actions\",[28,[32,1],null,[[\"post\",\"showLogin\"],[[30,1],[30,2,[\"showLogin\"]]]]]]],null],[1,\"\\n\"]],[\"@post\",\"@buttonActions\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-reactions/discourse/components/discourse-reactions-actions-button.js",
    "scope": () => [_mountWidget.default, _helper.hash],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "discourse-reactions-actions-button:ReactionsActionButton"));
  var _default = _exports.default = ReactionsActionButton;
});