define("discourse/plugins/discourse-reactions/discourse/widgets/discourse-reactions-state-panel-reaction", ["exports", "virtual-dom", "discourse/lib/icon-library", "discourse/lib/text", "discourse/widgets/post", "discourse/widgets/raw-html", "discourse/widgets/widget", "discourse-i18n"], function (_exports, _virtualDom, _iconLibrary, _text, _post, _rawHtml, _widget, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const MAX_USERS_COUNT = 26;
  const MIN_USERS_COUNT = 8;
  var _default = _exports.default = (0, _widget.createWidget)("discourse-reactions-state-panel-reaction", {
    tagName: "div.discourse-reactions-state-panel-reaction",
    buildClasses(attrs) {
      if (attrs.isDisplayed) {
        return "is-displayed";
      }
    },
    click(event) {
      if (event?.target?.classList?.contains("show-users")) {
        event.preventDefault();
        event.stopPropagation();
        this.sendWidgetAction("showUsers", this.attrs?.reaction?.id);
      }
    },
    html(attrs) {
      const elements = [];
      if (!attrs.users) {
        return;
      }
      elements.push((0, _virtualDom.h)("div.reaction-wrapper", [(0, _virtualDom.h)("div.emoji-wrapper", [new _rawHtml.default({
        html: (0, _text.emojiUnescape)(`:${attrs.reaction.id}:`)
      })]), (0, _virtualDom.h)("div.count", attrs.reaction.count.toString())]));
      const firsLineUsers = attrs.users.slice(0, MIN_USERS_COUNT);
      const list = firsLineUsers.map(user => (0, _post.avatarFor)("tiny", {
        username: user.username,
        template: user.avatar_template
      }));
      if (attrs.users.length > MIN_USERS_COUNT) {
        list.push((0, _virtualDom.h)("button.show-users", (0, _iconLibrary.iconNode)(attrs.isDisplayed ? "chevron-up" : "chevron-down")));
      }
      if (attrs.isDisplayed) {
        list.push(attrs.users.slice(MIN_USERS_COUNT, MAX_USERS_COUNT).map(user => (0, _post.avatarFor)("tiny", {
          username: user.username,
          template: user.avatar_template
        })));
      }
      let more;
      if (attrs.isDisplayed && attrs.reaction.count > MAX_USERS_COUNT) {
        more = (0, _discourseI18n.i18n)("discourse_reactions.state_panel.more_users", {
          count: attrs.reaction.count - MAX_USERS_COUNT
        });
      }
      const columnsCount = attrs.users.length > MIN_USERS_COUNT ? firsLineUsers.length + 1 : firsLineUsers.length;
      elements.push((0, _virtualDom.h)("div.users", [(0, _virtualDom.h)(`div.list.list-columns-${columnsCount}`, list), (0, _virtualDom.h)("span.more", more)]));
      return elements;
    }
  });
});