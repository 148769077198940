define("discourse/plugins/discourse-reactions/discourse/widgets/discourse-reactions-list", ["exports", "virtual-dom", "discourse/widgets/widget"], function (_exports, _virtualDom, _widget) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _widget.createWidget)("discourse-reactions-list", {
    tagName: "div.discourse-reactions-list",
    html(attrs) {
      if (attrs.post.reaction_users_count <= 0) {
        return;
      }
      return [(0, _virtualDom.h)("div.reactions", attrs.post.reactions.map(reaction => this.attach("discourse-reactions-list-emoji", {
        reaction,
        users: attrs.reactionsUsers[reaction.id],
        post: attrs.post
      })))];
    }
  });
});