define("discourse/plugins/discourse-reactions/discourse/widgets/discourse-reactions-double-button", ["exports", "discourse/widgets/widget"], function (_exports, _widget) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _widget.createWidget)("discourse-reactions-double-button", {
    tagName: "div.discourse-reactions-double-button",
    buildKey: attrs => `discourse-reactions-double-button-${attrs.post.id}`,
    html(attrs) {
      const items = [];
      const count = attrs.post.reaction_users_count;
      if (count > 0) {
        items.push(this.attach("discourse-reactions-counter", attrs));
      }
      if (!attrs.post.yours) {
        items.push(this.attach("discourse-reactions-reaction-button", attrs));
      }
      return items;
    }
  });
});